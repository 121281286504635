.grid {
    display: grid;
    grid-gap: 4rem;
	grid-template-columns: repeat(2, 1fr);

	&.grid-3 {
		grid-template-columns: repeat(3, 1fr);

		@include media-breakpoint-down(lg) {
			grid-template-columns: repeat(2, 1fr);
		}
		@include media-breakpoint-down(sm) {
			grid-template-columns: repeat(1, 1fr);
		}
	}

	&-dark {
		grid-gap: 2rem;
		.grid-item {
			background-color: var(--color-tertiary);
			padding: 2.5rem;

			h1,h2,h3,h4,h5,p,span,ul,ol,li,a {
				color: #ffffff;
			}
		}

	}
}