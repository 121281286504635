.footer {
    padding-block: 4rem;
	text-align: center;
    background-color: var(--bg-lightblue);

	&__list {
		display: flex;
		justify-content: center;
        margin-block-end: .5rem;
        
        @include media-breakpoint-down(md) {
            margin-block-end: .25rem;
            flex-wrap: wrap;
        }
	}

    &__item {
        position: relative;
        margin-inline-end: 0.5rem;
        font-weight: 400;
        white-space: nowrap; 

        &:not(:last-child) {
            &::after {
                content: "/";
                position: relative;
                display: inline-block;
                margin-inline-start: 0.5rem;
            }
        }
    }

    &__link {
        @include fluid-type($min_width, $max_width, 16px, 20px);
        color: var(--text);
        text-decoration: none;

        &:hover {
            color: var(--meta);
        }
    }

    &__copy {
        color: #000000;
        font-size: 16px;
        display: block;
        
        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }
    }
    &__details {
        color: var(--text);
        font-size: 14px;
        display: block;
        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }
}

.socialicons {
    display: flex;
    justify-content: center;
  
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      li {
          margin-left: 5px;
      margin-right: 5px;

      img {
        height: 35px;
        width: auto;
      }
      }
    }
  }