.hero {
    position: relative;
    // height: 100vh;
    min-height: 60dvh;
    // display: grid;
    display: flex;
    // place-items: center;
    padding-inline: 2rem;
    padding-block: 7rem;
    // background-color: #ffffff;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
        min-height: inherit;
        padding-inline: 0rem;
    }
}

.hero-image {
    position: absolute;
    inset: 0;
    overflow: hidden;
    z-index: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}



.introwrap {
    align-self: center;
    display: flex;
    flex-flow: column wrap;

    .hero-title {    
        font-size: 90px;
        color: var(--color-tertiary);
        z-index: 10;
        font-weight: 600;
        display: flex;
        align-items: center;

        @include media-breakpoint-down(lg) {
            font-size: 68px;
            // margin-top: 175px;
        }
        @include media-breakpoint-down(md) {
            font-size: 38px;
        }
        @include media-breakpoint-down(sm) {
            font-size: 28px;
        }

        &.text-center {
            text-align: center;
        }

        &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 118px;
            background-image: url(../img/layout/icon-x.svg);
            background-repeat: no-repeat;
            background-size: contain; 
            position: relative;
            margin-left: 1.5rem;

            @include media-breakpoint-down(lg) {   
                height: 84px;
            }
            @include media-breakpoint-down(md) {   
                height: 44px;
                margin-left: 1rem;
            }
            @include media-breakpoint-down(sm) {   
       
                height: 38px;
                margin-left: 0.6rem;
            }

        }
    }
    .hero-slogan {
        @include fluid-type($min_width, $max_width, 22px, 32px);
        color: var(--color-secondary);
        z-index: 10;
        font-weight: 600;
        display: block;
        max-width: 560px;
    }

}


.hero-detail {
    position: relative;
    background-color: var(--bg-dark);
    width: 100%;
    height: 100%;
    max-height: 800px;
    aspect-ratio: 3/2;
    
    &[data-size="full"] {
        height: 100vh;
        max-height: initial;
        aspect-ratio: initial;
        
        @include media-breakpoint-down(lg) {
            height: initial;
            aspect-ratio: 5/3;
        }
    }

    .image {
        position: absolute;
        inset: 0;
        overflow: hidden;
        z-index: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

.hero-btn {
    position: absolute;
    z-index: 10;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);

    @include media-breakpoint-down(lg) {
        top: 100px;
    }
}