.btn {
    display: inline-block;
    padding-inline: 2rem;
    padding-block: .75rem;
    color: white;
    @include fluid-type($min_width, $max_width, 16px, 20px);
    font-weight: 400;
    background-color: var(--btn-tertiary);
    border-radius: 50vw;
    text-decoration: none;
    @include effect();

    &:hover {
        background-color: var(--btn-tertiary-hover);       
    }

    &--secondary {
        color: #ffffff;
        background-color: var(--btn-secondary);
        
        &:hover {
            color: #ffffff;
            background-color: var(--btn-secondary-hover);       
        }
    }

    &--dark {
        background-color: var(--btn-dark);
        
        &:hover {
            background-color: var(--btn-dark-hover);       
        }
    }

    &--neutral {
        color: var(--title);
        background-color: var(--btn-neutral);
        
        &:hover {
            color: var(--title);
            background-color: var(--btn-neutral-hover);       
        }
    }
    &--clear {
        padding-inline: 1.75rem;
        padding-block: .45rem;

        color: var(--color-tertiary);
        background-color: #ffffff;
        border: 1px var(--color-tertiary) solid;
        
        &:hover {
            color: var(--title);
            background-color: var(--acc-btn);       
        }
    }
    &--arrow {
        position: relative;
        padding-inline: 1.75rem 2.5rem;
        &::after {
            display: block;
            content:'';
            position: absolute;
            width: 23px;
            height: 32px;
            background-color: var(--color-tertiary);
            mask-image: url("../img/layout/arrow-down.svg");
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: cover; 
            right: 15px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
}

.btn-bar {
    margin-block-start: 3rem;

    &--mb-4 {
        margin-block-end: 4rem;
    }
}