.circle {
	position: absolute;
	width: 150px;
	height: 150px;
	background-color: var(--color-secondary);
	mask-image: url("../img/layout/circle.svg");
	mask-repeat: no-repeat;
	mask-position: center;
	mask-size: 150px;
	top: inherit;
	left: inherit;
	right: inherit;
	bottom: inherit;	
	// opacity: 0;

	@include media-breakpoint-down(lg) {
		width: 120px;
		height: 120px;
		mask-size: 120px;
	}

	// @include media-breakpoint-down(sm) {
	// 	width: 60px;
	// 	height: 60px;
	// 	mask-size: 60px;
	// }	
	@include media-breakpoint-down(md) {
		display: none;
	}

	&.circle-scroll {
		position: fixed;
		display: block;
	}

	//colors
	&.yellow-circle {
		background-color: var(--bg-yellow);
	}
	&.green-circle {
		background-color: var(--bg-green);
	}
	&.turquoise-circle {
		background-color: var(--bg-turquoise);
	}
	&.blue-circle {
		background-color: var(--color-primary);
	}
	&.orange-circle {
		background-color: var(--color-secondary);
	}


	//sizes
	&.size-100 {
		width: 100px;
		height: 100px;
		mask-size: 100px;
		@include media-breakpoint-down(lg) {
			width: 80px;
			height: 80px;
			mask-size: 80px;
		}
	}
	&.size-200 {
		width: 200px;
		height: 200px;
		mask-size: 200px;
		@include media-breakpoint-down(lg) {
			width: 180px;
			height: 180px;
			mask-size: 180px;
		}
	}
	&.size-300 {
		width: 300px;
		height: 300px;
		mask-size: 300px;
		@include media-breakpoint-down(lg) {
			width: 240px;
			height: 240px;
			mask-size: 240px;
		}
		@include media-breakpoint-down(md) {
			width: 200px;
			height: 200px;
			mask-size: 200px;
		}
		@include media-breakpoint-down(sm) {
			width: 150px;
			height: 150px;
			mask-size: 150px;
		}
	}


	//positions
	&.position-1 {
		top: 40%;
		right: -30px;
		@include media-breakpoint-down(lg) {
			bottom: 0;
			right: -120px;
		}
		@include media-breakpoint-down(sm) {
			right: -40px;
		}
	}
	&.position-2 {
		top: -50px;
		right: 25%;
		@include media-breakpoint-down(lg) {
			top: -90px;		
		}
	}
	&.position-3 {
		top: 30%;
		left: -60px;
		@include media-breakpoint-down(sm) {
			top: 30%;
			left: -30px;
		}
	}
	&.position-4 {
		bottom: -60px;
		left: -60px;
		@include media-breakpoint-down(xl) {
			bottom: inherit;
			left: inherit;
			top: -60px;
			right: -60px;
		}
	}
	&.position-5 {
		top: 15%;
		right: 5%;
	}
	&.position-6 {
		bottom: -60px;
		right: -60px;
	}
	&.position-6a {
		bottom: -40px;
		right: -40px;
	}
	&.position-7 {
		bottom: 0;
		left: 0;
	}
	&.position-8 {
		top: 30px;
		right: -30px;
	}
}


.x-dots {
    position: absolute;
    width: 250px;
    aspect-ratio: 127 / 150;
    background-image: url(../img/layout/icon-x.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    top: 0;
    left: inherit;
    right: 0;
    bottom: 0;
	transform: rotate(-12deg);

	@include media-breakpoint-down(lg) {
		width: 200px;
	}
	@include media-breakpoint-down(md) {
		width: 150px;
	}
	@include media-breakpoint-down(sm) {
		width: 125px;
	}

	// @include media-breakpoint-down(sm) {
	// 	width: 60px;
	// 	height: 60px;
	// 	mask-size: 60px;
	// }

}