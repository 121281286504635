.figure {
    margin: 0;
    padding: 0;

    &--md {
        max-width: 640px;
    }

    &--sm {
        max-width: 480px;
    }

    &--xs {
        max-width: 320px;
    }
    &--xxs {
        max-width: 160px;
    }

    .img {
        width: 100%;
        height: auto;
        display: block;

        &-round {
            border-radius: 50%;
        }
    }
    
    .figcaption {
        margin-block-start: .5rem;
        font-size: 13px;
        font-weight: 400;
        font-style: italic;
        color: var(--meta);
    }
}

[data-img-size="1/1"] {
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
    width: 100%;
    // height: 100%;
}

[data-img-size="16/9"] {
    aspect-ratio: 16/9;
    object-fit: cover;
    object-position: center;
    width: 100%;
    // height: 100%;
}

[data-img-position="top"] {
    object-position: center 0 !important;
}

[data-img-position="top-5"] {
    object-position: center 5% !important;
}

[data-img-position="top-10"] {
    object-position: center 10% !important;
}

[data-img-position="top-mid"] {
    object-position: center 25% !important;
}

[data-img-position="mid-bottom"] {
    object-position: center 75% !important;
}

[data-img-position="bottom"] {
    object-position: center 100% !important;
}